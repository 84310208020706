import { ChartJsConfigWithDefaults } from 'editor-content/lib/chartBlockConversions.ts';

export type ChartReadyMessageData = {
  type: 'ready';
  source: 'chartjsFrame';
  id: string;
  instanceId: string;
};

export type ChartClickedMessageData = {
  type: 'clicked';
  source: 'chartjsFrame';
  id: string;
  instanceId: string;
};

export type ChartExportMessageData = {
  type: 'export';
  source: 'chartjsFrame';
  id: string;
  instanceId: string;
  data: string;
};

export type ChartConfigMessageData = {
  type: 'config';
  source: 'chartjsFrame';
  id: string;
  instanceId: string;
  data: ChartJsConfigWithDefaults;
  preview: boolean;
  animate: boolean;
};

export const isCleanupMessageData = (
  data: unknown,
): data is ChartCleanupMessageData => {
  if (typeof data !== 'object' || data === null) {
    return false;
  }

  if (!('source' in data) || data.source !== 'chartjsFrame') {
    return false;
  }

  if (!('type' in data)) {
    return false;
  }

  return data.type === 'cleanup';
};

export type ChartCleanupMessageData = {
  type: 'cleanup';
  source: 'chartjsFrame';
  id: string;
  instanceId: string;
};

export const isConfigMessageData = (
  data: unknown,
): data is ChartConfigMessageData => {
  if (typeof data !== 'object' || data === null) {
    return false;
  }

  if (!('source' in data) || data.source !== 'chartjsFrame') {
    return false;
  }

  if (!('type' in data)) {
    return false;
  }

  return data.type === 'config';
};

export const isExportMessageData = (
  data: unknown,
): data is ChartExportMessageData => {
  if (typeof data !== 'object' || data === null) {
    return false;
  }

  if (!('source' in data) || data.source !== 'chartjsFrame') {
    return false;
  }

  if (!('type' in data)) {
    return false;
  }

  return data.type === 'export';
};

export const isReadyMessageData = (
  data: unknown,
): data is ChartReadyMessageData => {
  if (typeof data !== 'object' || data === null) {
    return false;
  }

  if (!('source' in data) || data.source !== 'chartjsFrame') {
    return false;
  }

  if (!('type' in data)) {
    return false;
  }

  return data.type === 'ready';
};

export const isClickedMessageData = (
  data: unknown,
): data is ChartClickedMessageData => {
  if (typeof data !== 'object' || data === null) {
    return false;
  }

  if (!('source' in data) || data.source !== 'chartjsFrame') {
    return false;
  }

  if (!('type' in data)) {
    return false;
  }

  return data.type === 'clicked';
};
